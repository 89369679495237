.App-link {
  color: #61dafb;
}

h1 {
  margin: 4px;
  padding: 0px;
  font-size: 64px;
  color: #01161e;
  font-weight: 400;
}

h2 {
  margin: 4px;
  padding: 0px;
  font-size: 40px;
  color: #01161e;
  margin-inline: 8px;
  font-weight: 400;
}

h3 {
  margin: 4px;
  padding: 0px;
  font-size: 24px;
  color: #4c5f6b;
  margin-inline: 12px;
  font-weight: 400;
}

h4 {
  margin: 4px;
  padding: 0px;
  font-size: 20px;
  color: #01161e;
  margin-inline: 16px;
  font-weight: 400;
}

p {
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  color: #01161e;
  margin-inline: 20px;
}

.pressable {
  transition: 0.2s
}

.pressable:hover {
  opacity: 0.7;
}

.pressable:active {
  opacity: 1;
}

.error {
  color: #e54b4b;
}

.success {
  color: #7ac74f;
}

.menuLink {
  color: red;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding:12px;
  display: flex;
  flex: 1;
  font-weight: 600;
  color: #4C5F6B;
  transition: 0.5s;
  /* background: #fff */
}

.menuLink:hover {
  color:#443DF6;
  background: #fff
}