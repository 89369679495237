.jobCardLight {
    border:1px solid lightgray;
    transition: 0.5s;
    text-align: left;
}

.jobCardLight:hover {
    background-color: #fff;
    color: #006FFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    color: "#000"}

.jobCardDark {
    border: 1px solid lightgray;
    color:#eaeaea;
    background: transparent;
    transition: 0.5s;
    text-align: left;
}

.jobCardDark:hover {
    background-color: #fff;
    color: #006FFF;
    box-shadow: 0px 2px 8px rgba(0,0,0,0.25) ;
    color:"#000"
}

.shadow {
    transition: 0.4s;
    /* opacity:0.9 */
}

.shadow:hover {
    box-shadow: 0px 4px 12px rgba(0,0,0,0.25);
    /* opacity: 1 */
}