input {
    border: 1px solid #eaeaea;
    background-color: #fff;
    padding: 12px;
    font-size: 20px;
    border-radius: 8px;
}

label {
    flex-direction: column;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}

.jobCard {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    transition: 0.5s;
}

.jobCard:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}
